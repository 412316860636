@import "../../styles/styles.scss";

.card-div {
  margin-top: 8rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: #c7f0ff;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;

  h3 {
    font-size: 26px;
    color: $primary-color;
    text-align: center;
    font-weight: 700;
    margin: 1rem;
  }
  img {
    width: 14rem;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
