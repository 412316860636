@import "../../styles/styles.scss";

.contact {
  .form-svg {
    margin: 1rem 0;

    display: flex;
    justify-content: space-between;
    background-color: #eee;
    .contact-form {
      width: 50%;
      padding: 2rem;
    }
    .contact-cards {
      width: 50%;
      padding: 2rem;
      border: none;

      h3 {
        text-align: center;
        color: $secondary-color;
        font-size: 16px;
        font-weight: 600;
      }
      .accordion {
        color: #444;
        cursor: pointer;
        // padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        margin: 0;
        transition: 0.4s;

        @media (max-width: 768px) {
          padding-top: 2rem;
        }
      }

      .contact-ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 1.5;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: blue;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
      .contact-form {
        width: 100%;
        padding: 0;
      }
      .contact-cards {
        width: 100%;
        padding: 0;
      }
    }
  }
}
