@import "../../styles/styles.scss";
.about-section {
  .about {
    display: flex;
    margin: 1rem 0;

    .image {
      margin-right: 1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .txt {
      background-color: #c7f0ff;
      padding: 2rem;
      p {
        line-height: 1.4;
        font-weight: 700;
        font-size: 18px;
        color: #5d696f;

        span {
          color: $primary-color;
        }
      }
    }
  }


  .partners{
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 3fr;
    }


    li{
      padding: 8px;
      background-color: #c7f0ff;
      margin: 8px;
      text-align: center;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      @media (max-width: 768px) {
        padding: 4px;
      }

      span{
        text-align: justify;
      }
    }
    
   
  }
  .mission {
    h3 {
      border-bottom: 2px solid $primary-color;
    }
    p {
      text-align: justify;
      font-size: 18px;
      line-height: 28px;
      color: #5d696f;
    }
  }
}
.questions {
  padding: 2rem 0;
  h2 {
    color: $primary-color;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }
  .mission {
    padding: 1rem;
  }
}
