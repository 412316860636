@import "../../styles/styles.scss";

.hero {
  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    z-index: 1;
    li {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ccc;
      margin: 0 5px;
      color: transparent;
      position: relative;
      transition: all 0.3s ease;
      cursor: pointer;

      &.slick-active {
        background-color: $primary-color;
        z-index: 1000;
        transform: scale(1.2);
      }

      button {
        background-color: white;
        color: transparent;
        border-radius: 15px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        outline: none;
        padding: 0;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background-color: #665e5e;
          border-radius: 50%;
          transition: all 0.3s ease;
        }

        &:focus,
        &:hover {
          color: transparent;

          &:before {
            background-color: $primary-color;
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
      }
    }
  }

  .slider {
    padding: 0;
    margin: 0;
    img {
      width: 100%;
      object-fit: cover;
      height: 46rem;
    }

    .txt-div {
      position: absolute;
      bottom: 1%;
      padding: 10px 60px;
      width: 75%;
      height: 22rem;
      background-color: rgba($color: $primary-color, $alpha: 0.4);
      border-top-right-radius: 25px;
      @media (max-width: 768px) {
        padding: 1rem 0;
        position: absolute;
        bottom: 1%;
        border-radius: 0;
        height: 84%;
        width: auto;
      }
      @media (max-width: 458px) {
        p {
          font-size: 1.2rem;
          line-height: 1;
        }
      }

      h2 {
        color: #ffff;
        z-index: 1000;
        text-align: left;
        font-size: 38px;
        line-height: 1.2;
        padding: 1.5rem 0.8rem;
      }
      p {
        color: #ffff;
        font-size: 1.4rem;
        line-height: 1.2;
        padding: 1.5rem 0.8rem;
      }
    }
  }
}
.video-h2 {
  color: $primary-color;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  color: $primary-color;
  // text-align: center;
}

.video-section {
  background-color: #c7f0ff;
  padding:2rem 2rem;


    .txt {
      p {
        line-height: 1.6;
      }
    }
    .videos{
      display: flex;
      justify-content: space-between;
      .video {
        padding: 8px;
        video {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
   

  @media (max-width: 768px) {
    .video-txt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
    }
  }
}
.vision {
  padding: 2rem 2rem;
  margin: 0;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
}
.parallax {
  background-size: "cover";
  background-position: "center";
  background-repeat: "no-repeat";
}
.parallax-text {
  height: 28rem;

  h3 {
    color: #ffff;

    font-size: 2rem;
    font-weight: 400;
    line-height: 2;
    text-align: justify;
    word-wrap: break-word;
    background-color: rgba($color: $primary-color, $alpha: 0.3);
    height: 100%;
    padding: 2rem 3rem;

    span {
      color: $bs-light;
      font-size: 2rem;
      font-weight: 700;
      color: #e6e6e6;
    }
  }

  @media (max-width: 768px) {
    h3 {
      padding: 1rem;
      line-height: 1.2;
      font-size: 1.3rem;
      font-weight: 300;
      text-align: left;
    }
  }
}
