@import "../../styles/styles.scss";
.header-top {
  .navbar {
    position: fixed;
    max-width: 1440px;
    top: 0;
    background-color: rgba($color: #ffff, $alpha: 0.8);
    width: 100%;
    padding: 1rem 0rem;
    color: #fff;
    box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    .container {
      display: flex;
      justify-content: space-between;
    }

    .logo {
      height: auto;
      img {
        width: 16rem;
      }

      @media (max-width: 768px) {
        visibility: none;
      }
    }
  }

  .hamburger {
    display: none;
  }

  .menu {
    ul {
      display: flex;

      li {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 400;
        color: $primary-color;
        border-bottom: 2px solid transparent;
        transition: 0.5s;
        margin-left: 2.5rem;

        &:hover {
          border-bottom: 2px solid $primary-color;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .navbar {
      padding: 0rem 20px 8px 20px;
      align-items: center;
      .hamburger {
        position: relative;
        display: block;
        padding: 8px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        .fi-menu {
          font-size: 2rem;
          color: #00538a;
        }
      }
      .container {
        display: flex;
        flex-direction: column;
      }
      .menu {
        flex-direction: column;
        position: absolute;
        top: 6.4rem;
        left: 0;
        z-index: 1000;
        width: 100%;
        background-color: $primary-color;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;

        ul {
          display: block;
          li {
            color: #ffff;
            transition: 1s;
            border-bottom: 1px solid $secondary-color;
            padding: 8px 0;
            margin: 0;

            &:hover {
              background: white;
              color: black;
            }
          }
        }
      }

      .menu.show {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
      }
    }
  }
  @media (max-width: 458px) {
    .navbar {
      align-items: center;
      .hamburger {
        position: relative;
        display: block;
        padding: 8px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        .fi-menu {
          font-size: 2rem;
          color: #00538a;
        }
      }

      .menu.show {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
      }
    }
  }
}
