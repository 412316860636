@import "../../styles/styles.scss";

.footer {
  background-color: $primary-color;
  padding: 4rem 0 0 0;
  height: fit-content;
  opacity: 90%;
  text-align: center;
  .logo {
    height: auto;
    padding: 0;
    
    margin-bottom: 10px;
    img {
      margin-top: -10px;
      margin-left: -10px;
      width: 16rem;

    }
   

    @media (max-width: 768px) {
      visibility: none;
    }
  }
  .footer-div {
    padding: 0 2rem;
    display: flex;
    justify-content: center;

    div {
      text-align: left;
      padding: 10px;
      color: white;
      ul {
        margin: 0 1rem;
      }
      li {
        line-height: 1.5rem;
        font-weight: 300;
        font-size: 1rem;
      }
      h1 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
      p {
        line-height: 2rem;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 10px;
      }
      a {
        text-decoration: none;
        color: white;
      }
    }

    .bs {
      font-size: 1.5rem;
      margin: 0 0.5rem;
      color: white;
      transition: 0.5s;

      &:hover {
        color: #333;
        cursor: pointer;
        transform: translateY(-4px);
      }
    }
  }
  .footer-nav {
    padding: 1rem 0;
    background-color: #c7f0ff;
    display: flex;
    justify-content: space-evenly;
    place-items: center;
    margin-top: 3rem;
    span {
      color:$primary-color;
    }
    a {
      text-decoration: none;
      color: $primary-color;
    }
  }

  @media (max-width: 768px) {
    .footer-div {
      display: flex;
      padding: 1rem;
      flex-direction: column;
      justify-content: center;

      .bs {
        margin-bottom: 1rem;
      }
    }
    .footer-nav {
      display: block;
      justify-content: center;
      div {
        padding: 0.5rem 0;
      }
    }
  }
}
