@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@500;600;700&family=Maven+Pro:wght@400;600;700;800&family=Open+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Maven Pro";
}



.app{
  max-width: 1400px;
}
/* Hide the horizontal scrollbar */
body::-webkit-scrollbar {
  height: 0px;
}

/* Customize the vertical scrollbar */
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #00385e;
  outline: 1px solid slategrey;
}
.app{
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  word-wrap: break-word;

}