@import "../../styles/styles.scss";
.show-button {
  display: block;
  padding: 8px;
  border: none;
  position: fixed;
  bottom: 2rem;
  z-index: 1000;
  right: 2rem;
  border-radius: 25px;
  background-color: rgba($color: black, $alpha: 0.6);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  transition: 0.5s;
  .arrow-up {
    font-size: 2rem;
    color: $primary-color;
  }
  &:hover {
    transform: translateY(-8px);
    background-color: rgba($color: black, $alpha: 0.8);
  }
}
.hide-button {
  display: none;
}
