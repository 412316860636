@import "../../styles/styles.scss";

.principle {
  background-color: #c7f0ff;

  padding: 2rem 2rem;
  margin-top: -1rem;
  .cards-div {
    text-align: center;
  
    padding:2rem  0;
    h1 {
      span {
        color: $primary-color;
      }
    }
  }
.carousel{
  background-color: #a4e6e0;
  border-radius:  0 0 16px 16px;
  height: 400px;
  
}
  .cardData {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr ;
    margin: 0 1rem;


    .team {
      border-radius: 0.5rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin: 3rem 1rem;
      background-color: white;

    }
  

    @media (max-width: 968px) {
      display: flex;
      flex-direction: column;
    }
  }
}
