@import "../../styles/styles.scss";
.about-section {

    .cards-header{
        font-size: 20px;
        font-weight: 700;
        color: $primary-color;
        margin: 0;
    }
  }
