* {
  box-sizing: border-box;
}

// .container {
//   max-width: 1140px;
// }
$primary-color: #00538a;
$secondary-color: #1d9bd2;
$maven-regular: "MavenPro-Regular";

$primary-font: "MavenPro";

$bs-blue: #0d6efd;
$bs-indigo: #6610f2;
$bs-purple: #6f42c1;
$bs-pink: #d63384;
$bs-red: #dc3545;
$bs-orange: #fd7e14;
$bs-yellow: #ffc107;
$bs-green: #198754;
$bs-teal: #20c997;
$bs-cyan: #0dcaf0;
$bs-white: #fff;
$bs-gray: #6c757d;
$bs-gray-dark: #343a40;
$bs-gray-100: #f8f9fa;
$bs-gray-200: #e9ecef;
$bs-gray-300: #dee2e6;
$bs-gray-400: #ced4da;
$bs-gray-500: #adb5bd;
$bs-gray-600: #6c757d;
$bs-gray-700: #495057;
$bs-gray-800: #343a40;
$bs-gray-900: #212529;
$bs-primary: #0d6efd;
$bs-secondary: #6c757d;
$bs-success: #198754;
$bs-info: #0dcaf0;
$bs-warning: #ffc107;
$bs-danger: #dc3545;
$bs-light: #f8f9fa;
$bs-dark: #212529;
$bs-primary-rgb: rgb(13, 110, 253);
$bs-secondary-rgb: rgb(108, 117, 125);
$bs-success-rgb: rgb(25, 135, 84);
$bs-info-rgb: rgb(13, 202, 240);
$bs-warning-rgb: rgb(255, 193, 7);
$bs-danger-rgb: rgb(220, 53, 69);
$bs-light-rgb: rgb(248, 249, 250);
$bs-dark-rgb: rgb(33, 37, 41);
$bs-white-rgb: rgb(255, 255, 255);
$bs-black-rgb: rgb(0, 0, 0);
$bs-body-color-rgb: rgb(33, 37, 41);
$bs-body-bg-rgb: rgb(255, 255, 255);

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
}
.btn-primary {
  background-color: white;
  display: inline-block;
  font-weight: 300;
  line-height: 30px;
  border-radius: 0.5rem;
  padding: 8px 14px;
  font-size: 20px;
  margin-top: 37px;
  color: $secondary-color;

  border: 2px solid $secondary-color;
  transition: 0.5s;
  &:hover {
    color: white;
    background-color: $primary-color;
  }
}
.title {
  font-size: 48px;
  color: $secondary-color;
  font-weight: 500;

  line-height: 1.1;
}
.section-title {
  color: $primary-color;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  color: $primary-color;
  
}

.card-title {
  font-size: 28px;
  color: $primary-color;
  text-align: center;
  font-weight: 500;
  margin: 1rem;
}
.para {
  text-align: justify;
  color: #5d696f;
  font-size: 16px;
  line-height: 1.5;
letter-spacing: 2px;

overflow: hidden;

}
