@import "../../styles/styles.scss";

.director-title{
  font-size: 36px;
  padding: 16px 8px;
  text-align: center;
  color: $primary-color;
  border-bottom: 2px solid $primary-color;
  width: 100%;
}
.positions{
  font-size: 20px;
  color: $primary-color;

}

.position-ul{
  display: grid;
  grid-template-columns: 3fr 3fr ;
}
.allteam {
  display: grid;
  grid-template-columns:3fr 3fr;

  .team-card {
    text-align: center;
    overflow: hidden;

    img {
      width: 70%;
      object-fit: cover;
      height: auto;
    }
    h2 {
      font-size: 24px;
      margin-top: 1rem;
      color: #404041;
    }
   
  }
  
 
}
.team-div{
  display: flex;
  padding: 1rem;
}

.team-para {
  font-size: 16px;
  line-height: 28px;
  color: #5d696f;
  font-weight: 400;
  text-align: justify;
}

@media (max-width: 998px) {
  .allteam {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    .team-card {
      margin-bottom: 2rem;
      height: auto;

      img {
        height: auto;
      }
    }

    .team-para {
      text-align: left;
    }
  }
}
